import React, { useState, useEffect } from 'react';
import Post from './Post'
import Sidebar from './components/Sidebar'

const Blog = () => {
  const [posts, setPosts] = useState([])

  // const URL = import.meta.env.VITE_BASE_URL;

  useEffect(() => {
    //fetch('https://sope-backend.vercel.app/post').then(response => {
    fetch('http://localhost:4000/post').then(response => {
      response.json().then(posts => {
        setPosts(posts)
      })
    })
  }, [])

  const handleDeletePost = async (postId) => {
    try {
      // const response = await fetch(`https://sope-backend.vercel.app/post/${postId}`, { method: 'DELETE' });
      const response = await fetch(`http://localhost:4000/post/${postId}`, { method: 'DELETE' });
      if (response.ok) {
        setPosts(posts.filter((post) => post._id !== postId));
        alert('blog post has been deleted successfully')
      } else {
        console.error('Error deleting blog post:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting blog post:', error);
    }
  };

  return (
    <div className="flex">
      <Sidebar />
      <div className="w-full">
        <div className="px-5 py-4 bg-black text-white">
          <p className="capitalize text-lg" style={{fontFamily: "Muli"}}>welcome, admin</p>
        </div>
        <div className="grid grid-cols-2  gap-4 px-20 py-10">
          {posts.length > 0 && posts.map(post => (
            <Post {...post} onDelete={handleDeletePost} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Blog;