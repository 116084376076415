import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'

import arrowLeft from '../assets/arrow-left.png';
import arrowRight from '../assets/arrow-right.png';
import close from '../assets/close.png';

const Portfolio = () => {
  
	const [selectedImageIndex, setSelectedImageIndex] = useState(0);
	const [images, setImages] = useState([]);
	const [brandName, setBrandName] = useState('')
	const {brandName: urlBrandName} = useParams()

	const [isOpen, setIsOpen] = useState(false);
	const [currentIndex, setCurrentIndex] = useState(0);

  const [columns, setColumns] = useState(4);
  const [imageColumns, setImageColumns] = useState([]);

  // Responsive column adjustment
  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 640) setColumns(1);
      else if (screenWidth < 768) setColumns(2);
      else if (screenWidth < 1024) setColumns(3);
      else setColumns(4);
    };

    // Initial setup and add event listener
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Distribute images across columns
  useEffect(() => {
    if (images.length === 0) return;

    // Create empty columns
    const newColumns = Array.from({ length: columns }, () => []);

    // Distribute images across columns
    images.forEach((image, index) => {
      const columnIndex = index % columns;
      newColumns[columnIndex].push({ ...image, index });
    });

    setImageColumns(newColumns);
  }, [images, columns]);



  useEffect(() => {
    fetchImages();
    fetchBrand();
  }, []);
  
  const fetchImages = async () => {
    try {
      const response = await fetch(`https://sope-backend.vercel.app/upload/${urlBrandName}`);
      // const response = await fetch(`http://localhost:4000/upload/${urlBrandName}`);
      if (response.ok) {
        const data = await response.json();
        setImages(
          data.map((image) => ({
            src: image.image, // Use 'src' instead of 'original' for react-photo-gallery
            alt: image.alt,
          }))
        );
      } else {
        console.error('Error fetching images');
      }
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  const fetchBrand = async () => {
    try {
      const response = await fetch('https://sope-backend.vercel.app/upload');
      // const response = await fetch('http://localhost:4000/upload');
      if (response.ok) {
        const data = await response.json();

      	const matchingBrand = data.find(brand => brand.brandName === urlBrandName); // Assuming 'id' is the property to match
      	if (matchingBrand) {
          setBrandName(matchingBrand.brandName);
        }

      } else {
        console.error('Error fetching brands');
      }
    } catch (error) {
      console.error('Error fetching brands:', error);
    }
  };




  // Open the lightbox
  const openLightbox = (index) => {
    setCurrentIndex(index);
    setIsOpen(true);
  };

  // Close the lightbox
  const closeLightbox = () => {
    setIsOpen(false);
  };

  // Go to the next image
  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  // Go to the previous image
  const goToPrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };


  return (
    <>
    <div className="px-auto p-4 bg-[#252525] w-full">    	
	   	<div className="xl:pt-[20px] lg:pt-[20px] md:pt-[20px] sm:pt-[30px] pt-[10px]">

          <div className="w-full px-4 py-4">
            <div className="flex gap-4">
              {imageColumns.map((column, colIndex) => (
                <div 
                  key={colIndex} 
                  className="flex flex-col gap-4 flex-1"
                >
                  {column.map((image, imgIndex) => (
                    <div 
                      key={imgIndex} 
                      className="w-full transition-all duration-300 hover:scale-[1.02] cursor-pointer"
                      onClick={() => openLightbox(image.index)}
                    >
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="w-full object-cover shadow-md hover:shadow-xl"
                      />
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>

			</div>
    </div>

    {/* Lightbox */}
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="relative flex items-center justify-center w-full">
            {/* Previous Button */}
            <button
              onClick={goToPrev}
              className="absolute left-4 text-white text-3xl w-10 h-10 flex items-center justify-center"
            >
              <img width="24" height="24" src={arrowLeft} alt="Previous" />
            </button>

            {/* Image */}
            <img
              src={images[currentIndex].src}
              alt={`Lightbox ${currentIndex + 1}`}
              className="max-w-full max-h-screen"
            />

            {/* Next Button */}
            <button
              onClick={goToNext}
              className="absolute right-4 text-white text-3xl w-10 h-10 flex items-center justify-center"
            >
              <img width="24" height="24" src={arrowRight} alt="Next" />
            </button>

            {/* Close Button */}
            <button
              onClick={closeLightbox}
              className="absolute top-4 right-4 text-white text-3xl w-10 h-10 flex items-center justify-center"
            >
              <img width="24" height="24" src={close} alt="Close" />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Portfolio;
