import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import Sidebar from './components/Sidebar'


const About = () => {
  // const [image, setImage] = useState(null);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [formData, setFormData] = useState({
    publicationName: '',
    publicationNames: '',
    publicationLink: '',
  });
  const [honorsData, setHonorsData] = useState({
    HonorsName: '',
    HonorsNames: '',
    HonorsLink: '',
  });
  const [publications, setPublications] = useState([])
  const [honors, setHonors] = useState([])
  const [name, setName] = useState('')
  const [link, setLink] = useState('')
  const [files, setFiles] = useState('')
  const [press, setPress] = useState([]);

  // const URL = import.meta.env.VITE_BASE_URL;


  

  

  const handleImageChange = (event) => {
    setImages(event.target.files);
  };

  const handleUpload = async () => {
    try {
      const data = new FormData();
      data.set('name', name);
      data.set('link', link);
      data.set('file', files[0]);

      // const response = await fetch('http://localhost:4000/client', {
      const response = await fetch('https://sope-backend.vercel.app/client', {
        method: 'POST',
        body: data,
      });

      if (response.ok) {
        alert('press uploaded successfully');
        setName('');
        setLink('');
        setImages([]);

        // Refresh the page after successful submission
        window.location.reload();
      } else {
        console.error('Error uploading image:', response.statusText);
      }
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  const fetchUploadedPress = async () => {
    try {
      // const response = await fetch('http://localhost:4000/client');
      const response = await fetch('https://sope-backend.vercel.app/client');

      if (response.ok) {
        const data = await response.json();
        console.log("press", data.press)
        setPress(data.press || []); // Update state with the fetched items
      } else {
        console.error('Error fetching uploaded press:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching uploaded press:', error);
    }
  };

  useEffect(() => {
    fetchUploadedPress();
  }, []);

  const handleDeletePress = async (clientId) => {
    console.log('Deleting client with ID:', clientId);
    try {
      // const response = await fetch(`http://localhost:4000/client/${clientId}`, { 
      const response = await fetch(`https://sope-backend.vercel.app/client/${clientId}`, { 
        method: 'DELETE' 
      });

      if (response.ok) {
        setPress((prevPress) => prevPress.filter((item) => item._id !== clientId));
        alert('Press deleted successfully');
      } else {
        const errorData  = await response.json();
        console.error('Error deleting Image:', errorData);
      }
    } catch (error) {
      console.error('Error deleting Image:', error);
    }
  };



  // PUBLICATION FUNCTION
  const handlePublicationUpload = async () => {
    try {
      const response = await fetch('https://sope-backend.vercel.app/publications', {
      // const response = await fetch('http://localhost:4000/publications', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert('New publication successfully created')
        setFormData({
          publicationName: '',
          publicationNames: '',
          publicationLink: '',
        });

        // Refresh the page after successful submission
        window.location.reload();
      } else {
        console.error('Error creating portfolio title:', response.statusText);
      }
    } catch (error) {
      console.error('Error creating portfolio title:', error);
    } finally {
      setLoading(false); // End loading after submission completes
    }
  };

  useEffect(() => {
    // Fetch data from the backend
    fetchPublications();
  }, []);

  const fetchPublications = async () => {
      try {
        const response = await fetch('https://sope-backend.vercel.app/publications');
        // const response = await fetch('http://localhost:4000/publications');
        if (response.ok) {
          const data = await response.json();
          setPublications(data);
        } else {
          console.error('Error fetching data');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
  };

  const handleDeletePublication = async (publicationId) => {
    console.log('Deleting publication with ID:', publicationId);
    try {
      const response = await fetch(`https://sope-backend.vercel.app/publications/${publicationId}`, { 
      // const response = await fetch(`http://localhost:4000/publications/${publicationId}`, { 
        method: 'DELETE', 
      });

      if (response.ok) {
        // Remove the deleted publication from the state
        setPublications((prevPublications) => 
          prevPublications.filter((publication) => publication._id !== publicationId)
        );
        alert('Publication deleted successfully');
      } else {
        const errorData = await response.json();
        console.error('Error deleting publication:', errorData);
      }
    } catch (error) {
      console.error('Error deleting publication:', error);
    }
  };

  const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    };





  // HONORS FUNCTION

  const handleHonorsUpload = async () => {
    try {
      const response = await fetch('https://sope-backend.vercel.app/honors', {
      // const response = await fetch('http://localhost:4000/honors', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(honorsData),
      });

      if (response.ok) {
        alert('New honors successfully created')
        setHonorsData({
          honorsName: '',
          honorsNames: '',
          honorsLink: '',
        });

        // Refresh the page after successful submission
        window.location.reload();
      } else {
        console.error('Error creating honors title:', response.statusText);
      }
    } catch (error) {
      console.error('Error creating honors title:', error);
    } finally {
      setLoading(false); // End loading after submission completes
    }
  };

  useEffect(() => {
    // Fetch data from the backend
    fetchHonors();
  }, []);

  const fetchHonors = async () => {
      try {
        const response = await fetch('https://sope-backend.vercel.app/honors'); // Replace with your backend endpoint
        // const response = await fetch('http://localhost:4000/honors');
        if (response.ok) {
          const data = await response.json();
          setHonors(data);
        } else {
          console.error('Error fetching data');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
  };

  const handleDeleteHonors = async (honorsId) => {
    console.log('Deleting honors with ID:', honorsId);
    try {
      const response = await fetch(`https://sope-backend.vercel.app/honors/${honorsId}`, { 
      // const response = await fetch(`http://localhost:4000/honors/${honorsId}`, { 
        method: 'DELETE', 
      });

      if (response.ok) {
        // Remove the deleted honor from the state
        setHonors((prevHonors) => 
          prevHonors.filter((honor) => honor._id !== honorsId)
        );
        alert('Honor deleted successfully');
      } else {
        const errorData = await response.json();
        console.error('Error deleting honor:', errorData);
      }
    } catch (error) {
      console.error('Error deleting honor:', error);
    }
  };  

  const handleHonorsChange = (e) => {
      const { name, value } = e.target;
      setHonorsData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    };

  






  return (
    <>
      <div className="flex">
        <Sidebar />

        <div className="grid grid-cols-1 mb-20">
          <div className="w-full boder-b border-gray-300">
            <div className="px-2 py-4 bg-black text-white">
              <p className="capitalize text-lg" style={{fontFamily: "Muli"}}>welcome, admin</p>
            </div>
            <div className="p-2">
              <h2 className="text-4xl font-semibold mb-4 capitalize">upload image to press</h2>
              
              <input 
                style={{fontFamily: "Muli"}} 
                type="file" 
                onChange={(ev) => setFiles(ev.target.files)}
                className="cursor-pointer"
              />
              <input
                className="border border-solid border-black px-5 py-3 w-full"
                style={{ fontFamily: 'Muli' }}
                type="text"
                name="name"
                placeholder="press title"
                value={name}
                onChange={(ev) => setName(ev.target.value)}
              /><br /><br />
              <input
                className="border border-solid border-black px-5 py-3 w-full"
                style={{ fontFamily: 'Muli' }}
                type="text"
                name="link"
                placeholder="Link"
                value={link}
                onChange={(ev) => setLink(ev.target.value)}
              /><br /><br />
              <button
                type="button"
                className="text-white text-base w-[200px] bg-black px-10 py-4 uppercase"
                style={{ fontFamily: 'Muli' }}
                onClick={async () => {
                  setLoading(true);
                  await handleUpload();
                  setLoading(false);
                }}
              >
                {loading ? 'Uploading Press...' : 'Upload Press'}
              </button>

              {loading && (
                <div className="mt-3 text-white"> {/* Loader display */}
                  <p>Loading...</p>
                </div>
              )}

            </div>
            <div className="p-2 border-b border-gray-500 mb-10">
              <h2 className="text-4xl font-semibold mb-4 capitalize">Uploaded Images to Press</h2>
              <div className="overflow-x-auto">
                <table className="min-w-full border border-gray-300 mt-10">
                  <thead>
                    <tr className="bg-gray-100">
                      <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{fontFamily: "Muli"}}>s/n</th>
                      <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{fontFamily: "Muli"}}>image</th>
                      <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{fontFamily: "Muli"}}>press</th>
                      <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{fontFamily: "Muli"}}>link</th>
                      <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{fontFamily: "Muli"}}>delete press</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-300">
                    {press && press.length > 0 && press.map((pre, index) => (
                      <tr className="hover:bg-gray-100" key={pre._id}>
                        <td className="px-6 py-4 whitespace-nowrap" style={{fontFamily: "Muli"}}>{index + 1}</td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <img src={pre.cover} alt={`Item ${index + 1}`} className="w-1/5" />
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">{pre.name}</td>
                        <td className="px-6 py-4 whitespace-nowrap">{pre.link}</td>
                        <td className="px-6 py-4 whitespace-nowrap" key={pre.id}>
                          <button 
                            style={{fontFamily: "Muli"}}
                            className="bg-red-500 py-2 px-5 text-white font-semibold uppercase"
                            onClick={() => handleDeletePress(pre._id)}>
                            Delete
                          </button>
                        </td>
                      </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>



          {/*// PUBLICATIONS*/}
          <div>
            <div className="w-full mb-10 border-b border-gray-500">
              <div className="p-2">
                <h2 className="text-4xl font-semibold mb-4 capitalize">upload publications</h2>
                
                <input 
                  className="border mr-4 py-4 w-4/5 pl-2"
                  type="text" 
                  name="publicationName"
                  placeholder="Publication name" 
                  value={formData.publicationName}
                  onChange={handleInputChange}
                /><br/> <br/>
                <input 
                  className="border mr-4 py-4 w-4/5 pl-2"
                  type="text" 
                  name="publicationNames"
                  placeholder="Publication" 
                  value={formData.publicationNames}
                  onChange={handleInputChange}
                /><br/> <br/>
                <input 
                  className="border mr-4 py-4 w-4/5 pl-2"
                  type="text" 
                  name="publicationLink"
                  placeholder="Publication link" 
                  value={formData.publicationLink}
                  onChange={handleInputChange}
                /><br /> <br/>
                <button 
                  className="text-white text-base w-[200px] bg-black px-10 py-4 uppercase" 
                  style={{fontFamily: "Muli"}} 
                  onClick={handlePublicationUpload}>
                  {loading ? 'Uploading publication...' : 'Upload publication'}
                </button>

                {loading && (
                  <div className="mt-3 text-white"> {/* Loader display */}
                    <p>Loading...</p>
                  </div>
                )}

              </div>
              <div className="p-2 mb-10">
                <h2 className="text-4xl font-semibold mb-4 capitalize">Uploaded publications</h2>
                <div >
                  <div className="overflow-x-auto">
                    <table className="min-w-full border border-gray-300 mt-10">
                      <thead>
                        <tr className="bg-gray-100">
                          <th
                            className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                            style={{ fontFamily: "Muli" }}
                          >
                            s/n
                          </th>
                          <th
                            className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                            style={{ fontFamily: "Muli" }}
                          >
                            publication
                          </th>
                          <th
                            className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                            style={{ fontFamily: "Muli" }}
                          >
                            publication
                          </th>
                          <th
                            className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                            style={{ fontFamily: "Muli" }}
                          >
                            link
                          </th>
                          <th
                            className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                            style={{ fontFamily: "Muli" }}
                          >
                            delete client
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-300">
                        {publications.length > 0 &&
                          publications.map((pub, index) => (
                            <tr className="hover:bg-gray-100" key={pub._id}>
                              <td
                                className="px-6 py-4 whitespace-nowrap"
                                style={{ fontFamily: "Muli" }}
                              >
                                {index + 1}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {pub.publicationName}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {pub.publicationNames}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {pub.publicationLink}
                              </td>
                              {/* Uncomment this block for delete button functionality */}
                               <td className="px-6 py-4 whitespace-nowrap" key={pub.id}>
                                <button
                                  style={{ fontFamily: "Muli" }}
                                  className="bg-red-500 py-2 px-5 text-white font-semibold uppercase"
                                  onClick={() => handleDeletePublication(pub._id)}
                                >
                                  Delete
                                </button>
                              </td> 
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>

                </div>
              </div>
            </div>
          </div>




          {/* HONORS */}
          <div>
            <div className="w-full boder-b border-gray-300">
              <div className="p-2">
                <h2 className="text-4xl font-semibold mb-4 capitalize">upload honors</h2>
                
                <input 
                  className="border mr-4 py-4 w-4/5 pl-2"
                  type="text" 
                  name="honorsName"
                  placeholder="Publication name" 
                  value={honorsData.honorsName}
                  onChange={handleHonorsChange}
                /><br/> <br/>
                <input 
                  className="border mr-4 py-4 w-4/5 pl-2"
                  type="text" 
                  name="honorsNames"
                  placeholder="Publication link" 
                  value={honorsData.honorsNames}
                  onChange={handleHonorsChange}
                /><br /> <br/>
                <input 
                  className="border mr-4 py-4 w-4/5 pl-2"
                  type="text" 
                  name="honorsLink"
                  placeholder="Publication link" 
                  value={honorsData.honorsLink}
                  onChange={handleHonorsChange}
                /><br /> <br/>
                <button 
                  className="text-white text-base w-[200px] bg-black px-10 py-4 uppercase" 
                  style={{fontFamily: "Muli"}} 
                  onClick={handleHonorsUpload}>
                  {loading ? 'Uploading honors...' : 'Upload honor'}
                </button>

                {loading && (
                  <div className="mt-3 text-white"> {/* Loader display */}
                    <p>Loading...</p>
                  </div>
                )}

              </div>
              <div className="p-2">
                <h2 className="text-4xl font-semibold mb-4 capitalize">Uploaded honors</h2>
                <div className="overflow-x-auto">
                  <table className="min-w-full border border-gray-300 mt-10">
                    <thead>
                      <tr className="bg-gray-100">
                        <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{fontFamily: "Muli"}}>s/n</th>
                        <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{fontFamily: "Muli"}}>honors</th>
                        <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{fontFamily: "Muli"}}>honors</th>
                        <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{fontFamily: "Muli"}}>link</th>
                        <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{fontFamily: "Muli"}}>delete client</th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-300">
                      {honors.length > 0 && honors.map((hon, index) => (
                        <tr className="hover:bg-gray-100" key={hon._id}>
                          <td className="px-6 py-4 whitespace-nowrap" style={{fontFamily: "Muli"}}>{index + 1}</td>
                          <td className="px-6 py-4 whitespace-nowrap">{hon.honorsName}</td>
                          <td className="px-6 py-4 whitespace-nowrap">{hon.honorsNames}</td>
                          <td className="px-6 py-4 whitespace-nowrap">{hon.honorsLink}</td>
                          <td className="px-6 py-4 whitespace-nowrap" key={hon.id}>
                            <button 
                              style={{fontFamily: "Muli"}}
                              className="bg-red-500 py-2 px-5 text-white font-semibold uppercase"
                              onClick={() => handleDeleteHonors(hon._id)}>
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>







        </div>

      </div>
    </>
  );
};

export default About;