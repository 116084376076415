import React, { useState } from "react"
import { Play, X } from 'lucide-react';

import slideshow1 from "../assets/motions/1.jpg"
import slideshow2 from "../assets/motions/2.jpg"
import slideshow3 from "../assets/motions/3.jpg"
import slideshow4 from "../assets/motions/4.jpg"

const Motions = () => {

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [currentVideoUrl, setCurrentVideoUrl] = useState('');
	
	const videos = [
	    {
	      thumbnail: slideshow1,
	      videoUrl: "https://player.vimeo.com/video/1045008934?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
	    },
	    {
	      thumbnail: slideshow2,
	      videoUrl: "https://player.vimeo.com/video/1044074835?badge=0&autopause=0&player_id=0&app_id=58479/embed"
	    },
	    {
	      thumbnail: slideshow3,
	      videoUrl: "https://player.vimeo.com/video/1045013516?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
	    },
	    {
	      thumbnail: slideshow4,
	      videoUrl: "https://player.vimeo.com/video/1045021419?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
	    }
	];

	const openModal = (videoUrl) => {
	    setCurrentVideoUrl(videoUrl);
	    setIsModalOpen(true);
	};

	const closeModal = () => {
	    setIsModalOpen(false);
	    setCurrentVideoUrl('');
	};
	
	return (
		<>
			<div className="bg-[#252525] min-h-screen py-8 xl:px-[150px] lg:px-[150px] px-[30px]">
				<div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-6">
					{videos.map((video, index) => (
					<div className="flex justify-center items-center px-5">
		              <div className="relative w-full">
		                <div 
		                  className="relative cursor-pointer w-full h-[300px]" 
		                  onClick={() => openModal(video.videoUrl)}
		                >
		                  <img 
		                    src={video.thumbnail}
		                    alt={`Video thumbnail ${index + 1}`} 
		                    className="absolute w-full h-full object-cover"
		                  />
		                  <div className="absolute inset-0 bg-black/30 flex items-center justify-center">
		                    <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
		                      <Play size={64} className="text-white" />
		                    </div>
		                  </div>
		                </div>
		              </div>
		            </div>
		            ))}
				</div>
			</div>



			{/* Modal */}
		    {isModalOpen && (
		        <div className="fixed inset-0 z-50 flex items-center justify-center">
		          <div 
		            className="absolute inset-0 bg-black/90 backdrop-blur-sm"
		            onClick={closeModal}
		          />
		          
		          <div className="relative w-[95vw] h-[90vh] bg-black rounded-lg overflow-hidden">
		            <button 
		              onClick={closeModal}
		              className="absolute right-4 top-4 z-50 p-2 rounded-full bg-black/50 hover:bg-black/70 transition-colors"
		            >
		              <X className="h-6 w-6 text-white" />
		            </button>
		            
		            <iframe
		              src={currentVideoUrl}
		              frameBorder="0"
		              allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
		              className="w-full h-full"
		              title="Video Content"
		              allowFullScreen
		            />
		          </div>
		        </div>
		    )}
		</>
	)
}

export default Motions