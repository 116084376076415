import React, { useState, useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';

const EditBlog = () => {
  const { id } = useParams();
  const [title, setTitle] = useState('');
  const [summary, setSummary] = useState('');
  const [content, setContent] = useState('');
  const [files, setFiles] = useState('');
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    fetch('https://glintz-serverss.vercel.app/post/' + id)
      .then((response) => response.json())
      .then((postInfo) => {
        setTitle(postInfo.title);
        setContent(postInfo.content);
        setSummary(postInfo.summary);
      })
      .catch((error) => console.error('Error fetching post:', error));
  }, [id]);

  async function updatePost(ev) {
    ev.preventDefault();
    const data = new FormData();
    data.set('title', title);
    data.set('summary', summary);
    data.set('content', content);
    if (files?.[0]) {
      data.set('file', files?.[0]); // Corrected from file?.[0] to files?.[0]
    }

    const response = await fetch('http://localhost:4000/post/', {
      method: 'PUT',
      body: data,
      credentials: 'include',
    });

    if (response.ok) {
      setRedirect(true);
    } else {
      console.error('Failed to update post:', await response.text());
    }
  }

  if (redirect) {
    return <Navigate to={'/admin/blog/' + id} />;
  }

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }],
      ['link', 'image'],
      ['clean'],
    ],
  };

  return (
    <>
      <form onSubmit={updatePost} className="px-20 py-20">
        <input
          className="border border-black py-2 px-5"
          type="text"
          placeholder="Title"
          value={title}
          onChange={(ev) => setTitle(ev.target.value)}
        />
        <br />
        <br />
        <input
          className="border border-black py-2 px-5"
          type="text"
          placeholder="Summary"
          value={summary}
          onChange={(ev) => setSummary(ev.target.value)}
        />
        <br />
        <br />
        <input
          type="file"
          multiple
          onChange={(ev) => setFiles(ev.target.files)}
        />
        <br />
        <br />
        <ReactQuill
          value={content}
          onChange={(newValue) => setContent(newValue)}
          modules={modules}
        />
        <button type="submit" className="mt-4 px-6 py-2 bg-blue-500 text-black rounded">
          Update Post
        </button>
      </form>
    </>
  );
};

export default EditBlog;
