import React from "react"
import { Link, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { FaFacebook, FaTwitter } from "react-icons/fa";
import axios from 'axios';

const BlogPost = () => {
    const { slug } = useParams();
    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
      const fetchPost = async () => {
        try {
          const response = await fetch(`http://localhost:4000/post/${slug}`);
          if (!response.ok) {
            throw new Error('Failed to fetch the post');
          }
          const data = await response.json();
          setPost(data);
          console.log("blog post", data.content)
        } catch (err) {
          setError(err.message);
        } finally {
          setLoading(false);
        }
      };

      fetchPost();
    }, [slug]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;


    
    return (
        <div className="bg-[#252525] flex justify-center items-center px-5 min-h-screen">
          
            <div className="group xl:w-[60%] lg:w-[60%] md:w-[70%] w-full px-6 xl:px-0 lg:px-0 md:px-0 p-5 transition-all duration-300 transform">
                <div className="flex flex-row items-center">
                    <div>
                      <h1 className="text-3xl font-bold mb-4 uppercase text-white">{post.name}</h1>
                      <div className="mb-4 text-[#d5c9b4]">
                        <span className="capitalize text-[#d5c9b4]">{post.author}</span> |{' '}
                         {new Date(post.createdAt).toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                        })} 
                      </div>
                    </div>
                    <div className="ml-auto flex items-center space-x-4">
                        <a 
                          href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`} 
                          target="_blank" 
                          rel="noopener noreferrer" 
                          className="text-white hover:text-blue-600 transition-colors duration-300"
                        >
                          <FaFacebook size={24} />
                        </a>
                        <a 
                          href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}&text=${encodeURIComponent(post.name)}`} 
                          target="_blank" 
                          rel="noopener noreferrer" 
                          className="text-white hover:text-blue-400 transition-colors duration-300"
                        >
                          <FaTwitter size={24} />
                        </a>
                    </div>
                </div>
                <div>
                  <div>
                    {post.content.map((block, index) => {
                      // Check if block has a 'content' property or if the text is directly in 'text'
                      const text = block.content || block.text;
                      
                      if (text) {
                        return (
                          <p key={index} className="mb-4 text-md text-white">
                            {text}
                          </p>
                        );
                      }
                      
                      if (block.src) {
                        return (
                          <div key={index} className="mb-4">
                            <img
                              src={block.src}
                              alt={`Blog Image ${index + 1}`}
                              className="w-full h-auto"
                            />
                          </div>
                        );
                      }
                      
                      return null;
                    })}
                  </div>
                </div>
            </div>
        </div>
    );
};

export default BlogPost;
