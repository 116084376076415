// Dashboard.js
import { useState, useEffect, useRef } from 'react';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import AddressesTab from './AddressesTab'
import AccountSummary from './AccountSummary'

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState('summary');
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editName, setEditName] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [orders, setOrders] = useState([]);
  const [newAddress, setNewAddress] = useState('');
  const [addressData, setAddressData] = useState({
      address: '',
  });
  const [countries, setCountries] = useState([]);
  const [loadingCountries, setLoadingCountries] = useState(true);
  const { token, logout } = useAuth();
  const navigate = useNavigate();
  const inputRef = useRef(null);


  // Fetch the list of countries
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch('https://countriesnow.space/api/v0.1/countries/positions');
        const data = await response.json();
        if (data.error) {
          throw new Error(data.msg);
        }
        // Map only the country names
        const countryNames = data.data.map((country) => country.name).sort();
        setCountries(countryNames);
        setLoadingCountries(false);
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    fetchCountries();
  }, []);

  useEffect(() => {
    fetchUserData();
  }, [token]);

  const fetchUserData = async () => {
    try {
      const response = await fetch('http://localhost:4000/user/profile', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) throw new Error('Failed to fetch user data');
      const data = await response.json();
      setUserData(data);
      setEditName(data.name);

      // Fetch orders for the logged-in user
      fetchOrders(data._id);

    } catch (error) {
      console.error('Error:', error);
      logout();
      navigate('/login');
    } finally {
      setLoading(false);
    }
  };


  const fetchOrders = async (userId) => {
    try {
      const response = await fetch(`http://localhost:4000/orders?userId=${userId}`);
      if (!response.ok) throw new Error('Failed to fetch orders');
      const data = await response.json();

      console.log('Fetched orders data:', data);
      
      if (data.success) {
        setOrders(data.orders);
      }
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };


  const handleUpdateName = async () => {
    try {
      const response = await fetch('http://localhost:4000/user/update-name', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ name: editName }),
      });

      if (!response.ok) throw new Error('Failed to update name');
      
      await fetchUserData();
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating name:', error);
    }
  };


  

  const handleAddAddress = async () => {
  try {
    const selectedCountry = addressData.country;

    // Fetch the country code dynamically using the REST Countries API
    let countryCode = 'Unknown'; // Default fallback
    const apiUrl = `https://restcountries.com/v3.1/name/${encodeURIComponent(selectedCountry)}`;

    try {
      const response = await fetch(apiUrl);
      if (!response.ok) throw new Error('Failed to fetch country code');
      
      const data = await response.json();
      if (data && data.length > 0) {
        countryCode = data[0].cca2; // ISO 3166-1 alpha-2 country code
      } else {
        console.warn('Country not found in API response');
      }
    } catch (apiError) {
      console.error('Country code API error:', apiError);
    }

    // Proceed with adding the address
    const response = await fetch('http://localhost:4000/user/add-address', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ 
        address: addressData.address,
        country: selectedCountry,
        countryCode
      })
    });

    if (!response.ok) throw new Error('Failed to add address');

    await fetchUserData();
    console.log('Form submitted successfully');
    setAddressData({
      address: '',
      country: ''
    });
  } catch (error) {
    console.error('Error adding address:', error);
  }
};


  const handleDeleteAddress = async (addressToDelete) => {
    try {
      const response = await fetch('http://localhost:4000/user/delete-address', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ address: addressToDelete }),
      });

      if (!response.ok) throw new Error('Failed to delete address');

      await fetchUserData(); // Refresh the user data to update the address list
      console.log('Address deleted successfully');
    } catch (error) {
      console.error('Error deleting address:', error);
    }
  };


  const handleInputChange = (e) => {
      const { name, value } = e.target;
      setAddressData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
  };



  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-indigo-500"></div>
      </div>
    );
  }

  const TabButton = ({ id, label, active }) => (
    <button
      onClick={() => setActiveTab(id)}
      className={`px-4 py-2 font-medium text-sm ${
        active 
          ? 'bg-[#b7a78b] text-white' 
          : 'text-white hover:bg-[#d0c3a6]'
      }`}
    >
      {label}
    </button>
  );

    const OrdersTab = () => (
    <div className="bg-[#252525] shadow rounded-lg overflow-hidden">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-medium text-white">My Orders</h3>
      </div>
      <div className="border-t border-gray-200">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-[#252525]">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">S/N</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Payment ID</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Order ID</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">currency</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">product name</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Amount</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">size</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">quantity</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">date purchased</th>
              </tr>
            </thead>
            <tbody className="bg-[#252525] divide-y divide-gray-200">
              {orders.length > 0 ? (
                orders.map((order, index) => (
                  <tr key={order._id}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-white">#{index + 1}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-white">{order.payment_intent_id}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-white">{order._id}</td>
                    {/*<td className="px-6 py-4 whitespace-nowrap">
                      <span
                        className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                          order.status === 'Completed'
                            ? 'bg-green-100 text-green-800'
                            : order.status === 'Pending'
                            ? 'bg-yellow-100 text-yellow-800'
                            : 'bg-gray-100 text-gray-800'
                        }`}
                      >
                        {order.status}
                      </span>
                    </td>*/}
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-white">{order.currency}</td>
                    {/*<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{order.cart_items}</td>*/}
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                      <ul>
                        {order.cart_items.map((cartItemString, index) => {
                          // Parse the JSON string into an array of objects
                          const cartItems = JSON.parse(cartItemString);
                          return cartItems.map((item, itemIndex) => (
                            <li key={`${index}-${itemIndex}`}>
                              {item.title}
                            </li>
                          ));
                        })}
                      </ul>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-white">${order.amount / 100}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                      <ul>
                        {order.cart_items.map((cartItemString, index) => {
                          // Parse the JSON string into an array of objects
                          const cartItems = JSON.parse(cartItemString);
                          return cartItems.map((item, itemIndex) => (
                            <li key={`${index}-${itemIndex}`}>
                              {/*{item.title} - {item.cartQuantity} x */}
                              {item.selectedSize || 'N/A'}
                            </li>
                          ));
                        })}
                      </ul>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                      <ul>
                        {order.cart_items.map((cartItemString, index) => {
                          // Parse the JSON string into an array of objects
                          const cartItems = JSON.parse(cartItemString);
                          return cartItems.map((item, itemIndex) => (
                            <li key={`${index}-${itemIndex}`}>
                              {item.quantity || 'N/A'}
                            </li>
                          ));
                        })}
                      </ul>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                      {new Date(order.createdAt).toLocaleDateString()}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className="px-6 py-4 text-center text-sm text-white">
                    No orders found
                  </td>
                </tr>
              )}

            </tbody>
          </table>
        </div>
      </div>
    </div>
  );


  return (
    <div className="min-h-screen bg-[#252525]">
      {/* Navigation */}
      <nav className="bg-[#252525] shadow-lg">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <span className="text-xl font-semibold text-white">Dashboard</span>
            </div>
            <div className="flex items-center">
              <button
                onClick={() => {
                  logout();
                  navigate('/login');
                }}
                className="ml-4 px-4 py-2 text-sm font-medium text-white bg-[#b7a78b] hover:bg-[#d0c3a6]
                 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </nav>

      {/* Main Content */}
      <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        {/* Tabs */}
        <div className="mb-6 flex space-x-4">
          <TabButton id="summary" label="Account Summary" active={activeTab === 'summary'} />
          <TabButton id="orders" label="My Orders" active={activeTab === 'orders'} />
          <TabButton id="addresses" label="My Addresses" active={activeTab === 'addresses'} />
        </div>

        {/* Tab Content */}
        <div className="mt-6">
          {activeTab === 'summary' && (
            <AccountSummary
              userData={userData}
              handleUpdateName={handleUpdateName}
              edittName={editName}
              setEditName={setEditName}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />
          )}
          {activeTab === 'orders' && <OrdersTab />}
          {activeTab === 'addresses' && (
            <AddressesTab
              userData={userData}
              addressData={addressData}
              setAddressData={setAddressData}
              handleInputChange={handleInputChange}
              handleAddAddress={handleAddAddress}
              handleDeleteAddress={handleDeleteAddress}
              countries={countries}
              loadingCountries={loadingCountries}
            />
          )}
        </div>
      </main>
    </div>
  );
};

export default Dashboard;