import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import { Pagination } from 'swiper/modules';

import image0 from '../../assets/exhibition/1.jpg'


// import required modules

const Exhibition = () => {

	

	return (
		<>
			<div className="bg-[#252525] min-h-screen">
				<div className="xl:h-[500px] lg:h-[500px] md:h-[500px] h-[500px] 
					xl:px-20 xl:py-4 px-4 py-4">
					<Swiper pagination={true} modules={[Pagination]} className="mySwiper">
				        <SwiperSlide>
				        	<div className="flex justify-center items-center xl:px-40 md:px-20 px-5 ">
						      <iframe
						        src="https://player.vimeo.com/video/1041536456?badge=0&autopause=0&player_id=0&app_id=58479"
						        frameBorder="0"
						        allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
						        className="xl:w-[100%] w-[100%] xl:h-[400px] lg:h-[400px] md:h-[400px] h-[400px]"
						        title="In Her Hands Exhibition"
						        allowFullScreen
						      ></iframe>
						    </div>
				        </SwiperSlide>
				        <SwiperSlide>Slide 2</SwiperSlide>
				    </Swiper>


				    	{/*<div className="flex justify-center items-center xl:px-40 md:px-20 px-5 xl:pt-20 md:pt-20">
					      <iframe
					        src="https://player.vimeo.com/video/1041536456?badge=0&autopause=0&player_id=0&app_id=58479"
					        frameBorder="0"
					        allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
					        className="xl:w-[100%] w-[100%] xl:h-[400px] lg:h-[400px] md:h-[400px] h-[400px]"
					        title="In Her Hands Exhibition"
					        allowFullScreen
					      ></iframe>
					    </div>*/}

				        	{/*<div 
							  style={{
							    padding: "56.25% 0 0 0", // Keeps 16:9 aspect ratio
							    position: "relative",
							    maxWidth: "800px", // Set a maximum width
							    margin: "0 auto", // Center the video
							  }}
							>
							  <iframe 
							    src="https://player.vimeo.com/video/1041536456?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" 
							    frameBorder="0" 
							    allow="autoplay; fullscreen; picture-in-picture; clipboard-write" 
							    style={{
							      position: "absolute", 
							      top: "0", 
							      left: "0", 
							      width: "100%", 
							      height: "100%",
							    }} 
							    title="In Her Hands Exhibition"
							  >
							  </iframe>
							</div>*/}


				</div>

				<div className="px-0 xl:px-[15em] lg:px-10 
					space-y-8 mt-[30px] xl:mt-0 lg:mt-0 md:mt-[30px]
					order-2 xl:order-1 lg:order-1 md:order-2">
					<div className="border-b border-[#b7a78b] py-4">
						<p className="text-[#b7a78b] uppercase font-semibold md:text-3xl text-xl tracking-[0.05em]" 
							style={{fontFamily: "Hanken Grotesk"}}>exhibitions</p>
					</div>

					<div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 grid-cols-1 xl:px-10">
						<div>
							<img src={image0} alt="In Her Hands" />
							<p className="capitalize text-center mt-4">
								<a href="/exhibitions/in-her-hands" className="text-[#b7a78b] text-xl">in her hands</a>
							</p>
						</div>
					</div>
				</div>

			</div>


			
		</>
	)
}

export default Exhibition 