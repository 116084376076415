import React, { useState, useEffect } from 'react';
import Sidebar from './components/Sidebar';

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    try {
      const response = await fetch('http://localhost:4000/orders'); // Ensure correct endpoint
      if (response.ok) {
        const data = await response.json();
        setOrders(data.orders);
        setLoading(false);
      } else {
        console.error('Error fetching orders:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };

  const handleDeleteOrder = async (orderId) => {
    try {
      const response = await fetch(`http://localhost:4000/orders/${orderId}`, { method: 'DELETE' });
      if (response.ok) {
        setOrders(orders.filter((order) => order._id !== orderId));
        alert('Order deleted successfully.');
      } else {
        console.error('Error deleting order:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting order:', error);
    }
  };

  if (loading) {
    return <div>Loading orders...</div>;
  }

  return (
    <div className="flex">
      <Sidebar />
      <div className="p-10">
        <h2 className="text-4xl font-semibold mb-4 capitalize">Orders</h2>

        <table className="min-w-full border border-gray-300 mt-10">
          <thead>
            <tr className="bg-gray-100">
              {[
                'S/N',
                'Payment ID',
                'Name',
                'Email',
                'Phone',
                'Address',
                'Country',
                'Product Name',
                'Quantity',
                'Size',
                'Price',
                'Product ID',
                'Actions',
              ].map((heading) => (
                <th
                  key={heading}
                  className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                >
                  {heading}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-300">
            {orders.map((order, index) => {
              const customer = order.customer_details || {};

              let cartItems = [];
              try {
                cartItems = JSON.parse(order.cart_items);
              } catch (error) {
                console.error('Error parsing cart items:', error);
              }
              
              return (
              
              <tr key={order._id} className="hover:bg-gray-100">
                <td className="px-6 py-4 whitespace-nowrap">{index + 1}</td>
                <td className="px-6 py-4 whitespace-nowrap">{order.payment_intent_id}</td>
                <td className="px-6 py-4 whitespace-nowrap">{customer.name || 'N/A'}</td>
                <td className="px-6 py-4 whitespace-nowrap">{customer.email}</td>
                <td className="px-6 py-4 whitespace-nowrap">{customer.phone}</td>
                <td className="px-6 py-4 whitespace-nowrap">{customer.address}</td>
                <td className="px-6 py-4 whitespace-nowrap">{customer.country}</td>
                {/* Render cart items details */}
                {cartItems.map((item, idx) => (
                  <React.Fragment key={idx}>
                    <td className="px-6 py-4 whitespace-nowrap">{item.title}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{item.cartQuantity}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{item.selectedSize}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{item.price}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{item.id}</td>
                  </React.Fragment>
                ))}
                <td className="px-6 py-4 whitespace-nowrap">
                  <button
                    className="bg-red-500 py-2 px-5 text-white font-semibold uppercase"
                    onClick={() => handleDeleteOrder(order._id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Orders;
