import React, { useState, useEffect } from "react"
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import {Helmet} from "react-helmet";
import image from "../assets/ourworks2.jpg"

import three from '../assets/homepage/3.jpg'
import four from '../assets/homepage/4.jpg'
import fifteen from '../assets/homepage/15.jpg'
import slider from '../assets/homepage/14.jpg'
import slider2 from '../assets/homepage/11.jpg'


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const Blog = () => {

  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true); // Optional loading state
  const [error, setError] = useState(null);

	const fetchProducts = async () => {
	    try {
	      // const response = await fetch('https://sope-backend.vercel.app/post');
	      const response = await fetch('http://localhost:4000/post');
	      console.log('Response status:', response.status)
	      if (response.ok) {
	        const data = await response.json();
	        setProducts(data);
	        console.log("blog", data);
	      } else {
	        console.error('Error fetching products');
	        setError('Failed to fetch blog posts');
	      }
	    } catch (error) {
	      console.error('Error fetching products:', error);
	      setError('An error occurred while fetching blog posts');
	    } finally {
      setLoading(false);
    }
	};

	useEffect(() => {
	    fetchProducts();
	}, []);

	if (loading) {
    return <p>Loading blog posts...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

	return (
		<>
			<Helmet>
		        <title>Sope Adelaja | Photographer - Film Director</title>
		        <meta name="description" content="photographer, photography, portrait, commercial photographer" />
		    </Helmet>
			<div className="xl:h-[300px] lg:h-[300px] md:h-[300px] h-[300px] 
				xl:px-20 xl:py-4 md:px-4 md:py-4 bg-[#252525]">
				<Swiper
			        spaceBetween={30}
			        centeredSlides={true}
			        autoplay={{
			          delay: 2500,
			          disableOnInteraction: false,
			        }}
			        pagination={{
			          clickable: true,
			        }}
			        // navigation={true}
			        modules={[Autoplay, Pagination]}
			        className="mySwiper"
			    >
			    	<SwiperSlide>
			          <div className="relative w-full h-full">
				        <img src={slider} alt="" className="w-full h-full object-cover" />
				        <div className="absolute inset-0 bg-black bg-opacity-50"></div>

				        <div className="absolute bottom-5 right-5 w-[400px]">
				          <p className="text-[#b7a78b] text-2xl text-left font-semibold uppercase tracking-[.15em]">jos crisis</p>
				          <p className="text-white text-left">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt 
				          ut labore et dolore magna aliqua.</p>
				        </div>
				      </div>
				   	</SwiperSlide>
			        <SwiperSlide>
				      <div className="relative w-full h-full">
				        <img src={slider2} alt="" className="w-full h-full object-cover" />

				        <div className="absolute inset-0 bg-black bg-opacity-50"></div>

				        <div className="absolute bottom-5 right-5 w-[400px]">
				          <p className="text-[#b7a78b] text-2xl text-left font-semibold uppercase tracking-[.15em]">kano nigeria</p>
				          <p className="text-white text-left">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt 
				          ut labore et dolore magna aliqua.</p>
				        </div>
				      </div>
				    </SwiperSlide>
			    </Swiper>
		    </div>

			
			<section className="pb-24 bg-[#252525]">
		        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
		          <div className="border-b border-[#b7a78b] py-4 mb-6">
								<h2 className="uppercase text-[#b7a78b] text-2xl font-semibold tracking-[.11em]" 
									style={{fontFamily: "Hanken Grotesk"}}>sope adelaja's blog</h2>
						  </div>
						  {products.length === 0 ? (
				        <div className="flex justify-center items-center h-64">
					        <p className="text-center text-[#d5c9b4] text-lg">
					          No blog posts found.
					        </p>
					      </div>
				      ) : (
			          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-8 lg:gap-y-0 gap-x-5 mb-14 justify-center">
				      	{products.map((post) => (
			            <a href={`/thoughts/${post.name}`}>
			            <div 
			            	key={post.id}
			            	className="group cursor-pointer w-full p-5 transition-all duration-300 transform hover:scale-105 hover:bg-[#252525] hover:shadow-lg">
				            	<div className="flex items-center mb-6 overflow-hidden h-64">
				                  <img 
				                  	src={post.cover[0]} 
				                  	alt="Harsh image" 
				                  	className="w-full object-cover transition-transform duration-500 group-hover:scale-110"
				                  />
				            	</div>
			    	        	<div className="block text-center">
			                		<h4 className="text-[#d5c9b4] text-[21px] leading-8 mb-2 capitalize group-hover:text-white transition-colors duration-300">
			                			{post.name}
			                		</h4>
			                		<div className="flex items-center justify-center font-medium">
			                    		<h6 className="text-[13px] text-gray-500 group-hover:text-gray-400 transition-colors duration-300 capitalize">{post.author}</h6>
			                    		<span className="px-2 text-white">--</span>
			                    		<span className="text-[13px] text-white capitalize">
			                    			{new Date(post.createdAt).toLocaleDateString('en-US', {
															    year: 'numeric',
															    month: 'long',
															    day: 'numeric',
															  })}
															</span>
			                		</div>
			            		</div>
			            	</div>
			            </a>
			            ))}
			          </div>
		          	)}
		            

		            {/*
		          <a href="javascript:;" 
		          	className="cursor-pointer border border-[#b7a78b]
		          		shadow-sm py-3.5 px-7 w-52 flex justify-center items-center 
		          		text-white font-semibold mx-auto transition-all duration-300 
		          		hover:bg-[#b7a78b] hover:text-black">View All</a>*/}
		        </div>
		    </section>

		</>
	)
}

export default Blog